<template>
  <div>
    <div v-show="isBusy">
      <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <div v-show="!isBusy" v-if="actualUser" >
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="actualUser.displayName">{{actualUser.displayName}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>User name</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update user</md-button>
      </md-toolbar>
      <div class="pl-3 pr-3">
        <CRow>
          <CCol col="12" lg="4">
            
            <form enctype="multipart/form-data">
              <md-field :class="getValidationClass('email')">
                  <label for="email">Email</label>
                  <md-input type="email" name="email" id="email" autocomplete="off" v-model="actualUser.email" :disabled="true" />
                  <span class="md-error" v-if="!$v.actualUser.email.required">The email is required</span>
                  <span class="md-error" v-else-if="!$v.actualUser.email.email">Invalid email</span>
              </md-field>
              <md-field :class="getValidationClass('displayName')">
                  <label for="display-name">Display Name</label>
                  <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="actualUser.displayName"  />
                  <span class="md-error" v-if="!$v.actualUser.displayName.required">Display name is required</span>
                  <span class="md-error" v-else-if="!$v.actualUser.displayName.minlength">Invalid first name</span>
              </md-field>
              <md-field :class="getValidationClass('role')">
                  <label for="role">Role</label>
                  <md-select v-model="actualUser.role" name="role" id="role" @md-selected="roleSelected()">
                      <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                      
                  </md-select>
                  <!--span class="md-error" v-if="!$v.actualUser.role.required">Role is required</span-->
              </md-field>

              <md-field :class="getValidationClass('subrole')" v-if="actualUser.role === 'driver'">
                  <label for="subrole">Driver type</label>
                  <md-select v-model="actualUser.subrole" name="subrole" id="subrole">
                      <md-option  :value="'owner-operator'">Owner operator (I have my own car)</md-option>
                      <md-option  :value="'company'">Company driver (I drive a vehicle from company fleet)</md-option>
                      
                  </md-select>
                  <span class="md-error" v-if="!$v.actualUser.subrole.required">Subrole is required</span>
              </md-field>
              <md-field >
                  <label for="code">Code</label>
                  <md-input name="code" id="code" autocomplete="given-name" v-model="actualUser.code"  />
                
              </md-field>
              
            </form>
          </CCol>
        </CRow>
      </div>
      
    </div>
  </div>
    
 
  
</template>

<script>
import usersData from './UsersData'
import axios from "./../../helpers/axios";
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'


export default {
  name: 'User',
  mixins: [validationMixin],
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.usersOpened = from.fullPath.includes('users')
  //   })
  // },
  data () {
    return {
      isBusy: false,
      usersOpened: null,
      actualUser: null,
      roles: [
          
        {
            name: 'company-admin',
            text: 'Company admin'
        },
        {
            name: 'driver',
            text: 'Driver'
        },
        {
            name: 'dispatcher',
            text: 'Dispatcher'
        },
        {
            name: 'affiliate',
            text: 'Affiliate'
        },
        {
            name: 'passenger',
            text: 'Passenger'
        },
        {
            name: 'client',
            text: 'Corporate client'
        },
          
      ]
    }
  },
  created () {
    let th = this;
    this.obtainViewResources()
  },
  computed: {
    
    user() {
      return this.$store.state.user;
    },
    
  },
  
  methods: {
    fireUpdate(){
      this.updateUser()
    },
    async updateUser(){
      const id = this.$route.params.id
      
      let data = {
         displayName: this.actualUser.displayName, 
         //password, 
         email: this.actualUser.email,
         claims: {
           role: this.actualUser.role,
           subrole: this.actualUser.subrole,
           code: this.actualUser.code,
         }
      }

      return new Promise((resolve, reject) => {
          axios.patch('users/' + id , data).then(response =>{
              console.log(response);
              
              resolve(response)
              //th.$router.push({path: '/users/drivers'})
          }).catch(e => {
              console.log(e);
              
              reject(e)
              
          }) 
      });

    },
    roleSelected() {
      
      if(this.actualUser.role != 'driver') {
        this.actualUser.subrole = null
      }
    },
    getValidationClass (fieldName,index) {
        let field = null;
        //const field = this.$v[fieldName]
        if(
            fieldName == 'email' 
            || fieldName == 'displayName' 
            || fieldName == 'password' 
            || fieldName == 'name' 
            || fieldName == 'lastname' 
            ) {
            // General Step fields
            //field = this.$v.driver.general[fieldName]
        }

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    async obtainViewResources(){
      this.isBusy = true
      const user = await this.getUser()
      this.actualUser = user.data.user;
      this.isBusy = false
    },
    async getUser(){
        const id = this.$route.params.id
        return new Promise((resolve, reject) => {
            axios.get('users/' + id ).then(response =>{
                console.log(response);
                
                resolve(response)
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                
                reject(e)
                
            }) 
        });
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
    }
  },
  validations: {
    actualUser: {
      email: {
        required,
        email
      },
      displayName: {
        required,
        minLength: minLength(6)
      },
      role: {
        required
      },
      subrole: {
        required: requiredIf(function(){
          
          return this.actualUser.role === 'driver' ? true : false;
        }),
      }
    }
  }
}
</script>
